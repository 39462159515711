import React, { useEffect, useState } from "react";
import wordpressService from "../../services/wordpressService";
import image from "../../images/result.png";
import analysisService from "../../services/analysisService";
import { baseReportData } from "../../data/baseReportData";
import { baseOptionsData } from "../../data/baseOptionsData";
import { Link, useLocation } from "react-router-dom";

const redirectUrl = "https://app.formationscorp.com/";

export const ResultBlock = () => {
  const [reportData, setStepsData] = useState(baseReportData);
  const [optionsData, setOptionsData] = useState(baseOptionsData);
  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      setStepsData(await wordpressService.getData("report"));
      setOptionsData(await wordpressService.getData("options"));
    })();
  }, []);

  let analysis = analysisService.analysis(reportData);

  return (
    <div className="result__inner">
      <div className="result__card">
        <div className="result__card-img">
          <img src={image} alt="result" />
        </div>
        <div className="result__card-text">
          <h2 className="result__card-title">
            YOU CAN KEEP UP TO $
            {analysis.savingsCalculationEnd
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h2>
          <p className="result__card-subtitle">in your pocket every year!</p>
          <p className="result__card-descr">
            Based on your inputs, you can add{" "}
            {analysis.savingsCalculationDiapason
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            to your household income every year by getting your financials in
            order and following our recommendations.
          </p>
          <div className="result__buttons">
            <a href={redirectUrl} className="button button-yellow">
              Start Saving Today
            </a>
          </div>
          <div className="result__card-apply-to-join">
            <p>
              Formations currently serves businesses-of-one making over $100K
              annually in selection states and industries. Click{" "}
              <a href={redirectUrl}>here</a> to apply to join.
            </p>
          </div>
          <div className="result__card-reanalyse">
            <p>
              Think something went wrong with the results of your calculations?{" "}
              <Link to={`/${search}`}>Re-Analyze</Link>
            </p>
          </div>
        </div>
      </div>
      <div className="result__bullet">
        <h3>{optionsData.acf.summary_title}</h3>
        <ul className="card-list">
          {optionsData.acf.summary.map((data, key) => {
            return (
              <li
                className="card-list__item"
                key={key}
                dangerouslySetInnerHTML={{ __html: data.summary_text }}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};
