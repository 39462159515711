export const baseResultsData = {
  acf: {
    sidebar: {
      sidebar_title: "What’s next?",
      sidebar_items: [
        {
          text: "<p>Are you ready to take the next step? Join Formations to start managing your taxes and save thousands of dollars every year.</p>\n",
          button_text: "Start Saving Today",
          button_link: "https://app.formationscorp.com/",
        },
      ],
    },
    sidebar_posts: {
      sidebar_items: false,
    },
    footer_bool: false,
  },
};
